/**
 * @prettier
 */
import { FRESHDESK_GA_CATEGORY } from 'utils/constants'

document.addEventListener('DOMContentLoaded', () => {
  if (window.fwSettings) {
    Utils.trackGAEvent(FRESHDESK_GA_CATEGORY, 'render')
  }
})
